import { SafeYTVideoEditor } from '@pbeejcom/safeyt-video-editor';
import { useCallback, useState } from 'react';
import TextField from '@mui/material/TextField';
import Collapse from '@mui/material/Collapse';
import Fab from '@mui/material/Fab';
import logo from '~/assets/pbeej-logo.png';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

function Home() {
  const [link, setLink] = useState<string>("");
  const [outputLink, setOutputLink] = useState<string>();
  const [viewingLink, setViewingLink] = useState(false);
  const [copiedLink, setCopiedLink] = useState(false);

  const onLinkInput = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setLink(event.target.value);
  }, []);

  const openLinkDialog = () => {
    setCopiedLink(false)
    setViewingLink(true)
  }

  const closeLinkDialog = () => {
    setViewingLink(false)
  }

  const copyLink = () => {
    navigator.clipboard.writeText(outputLink || '')
    setCopiedLink(true)
  }

  return (
    <div className='home'>
      <Collapse in={!link}>
        <img src={logo} width={500} />
      </Collapse>
      <div className='link-input-container'>
        <TextField
          className='link-input'
          placeholder='YouTube or SafeYT URL'
          onChange={onLinkInput}
        />
      </div>
      <div className='video-container'>
        <SafeYTVideoEditor width={700} height={400} isEditMode={true} link={link} onSafeYTLinkChange={(link: string) => setOutputLink(link)} />
        { link ? <Fab disabled={!outputLink} color="primary" variant="extended" onClick={openLinkDialog} className="'done-button">
          <CheckCircleIcon sx={{ mr: 1 }} />
          Done
        </Fab> : null }
      </div>

      <Dialog open={viewingLink}>
        <DialogTitle>Video Link</DialogTitle>
        <DialogContent>
          <DialogContentText noWrap={true}>
            { copiedLink ? <DoneOutlineIcon color="success"> test</DoneOutlineIcon> : null } {outputLink}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeLinkDialog}>Close</Button>
          <Button onClick={copyLink} color="success">
            Copy
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Home;
