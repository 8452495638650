import React from 'react';
import { useParams } from 'react-router-dom';
import { SafeYTVideoEditor } from '@pbeejcom/safeyt-video-editor';

const Embed = () => {
  let { encodedVideoInformation } = useParams();

  return <SafeYTVideoEditor width={700} height={400} isEditMode={false} link={`https://safeyt.pbeej.com/embed/${encodedVideoInformation}`} onSafeYTLinkChange={function (safeYTLink: string): void {
    console.warn('SafeYT link changed in viewer mode - this is not expected behavior.');
  } } />;
};

export default Embed;
