import React from 'react';
import ReactDOM from 'react-dom';
import Embed from './pages/embed';
import Home from './pages/home';
import {
  BrowserRouter,
  Route,
  Routes,
} from 'react-router-dom';

ReactDOM.render(
    <React.StrictMode>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/embed/:encodedVideoInformation' element={<Embed />} />
        </Routes>
      </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
  );
